<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="../assets/activateYourFutureAdjusted.jpg" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Learn New Skills to go ahead for Your Career</h2>
                    <h6>We can support student forum 24/7 for national and international students.</h6>
                    <p>
                        Welcome to our student forum, available 24/7 to support both national and international students. Connect with peers, share experiences, and get help navigating academic and cultural challenges. Our dedicated team ensures a
                         supportive community for global knowledge-sharing and assistance throughout your educational journey.
                    </p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>
                            TCFL has launched a new campus wide WIFI network for students. The new Wi-Fi system utilises vouchers. These vouchers are issued to students upon registration
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>