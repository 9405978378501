<section class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <span class="sub-title">Discover Learning</span>
                <h2>Why TCFL</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h3>Quality can be better than quantity</h3>
                        <p> We prioritize meaningful contributions for an enhanced user experience. Encouraging valuable, insightful, and accurate information over volume fosters a community of thoughtful discussions and shared expertise. Our emphasis on quality creates a space where users can engage, learn, and collaborate effectively.</p>
                        <p> Quality contributions build individual credibility and enrich the platform's knowledge. We appreciate users who offer well-reasoned responses, share expertise, and uphold a high content standard. Together, we prioritize quality interactions over quantity, creating an environment of excellence. Thank you for being part of this commitment! </p>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>A place where you can achieve</h3>
                        <p>Discover a space where learning transcends boundaries! Our center is a haven for those seeking knowledge and growth. Achieve your academic and personal goals with expert guidance and innovative resources.</p>
                        <p> Join a community dedicated to fostering a love for learning, where every individual journey is valued. Welcome to a place where education becomes a transformative experience!</p>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>