import { Component } from '@angular/core';

@Component({
  selector: 'app-administration-circular',
  templateUrl: './administration-circular.component.html',
  styleUrls: ['./administration-circular.component.scss']
})
export class AdministrationCircularComponent {

}
