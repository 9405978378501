<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Admissions</li>
            </ul>
            <h2>Application Forms</h2>
        </div>
    </div>
</div>

<div class="beautiful-div">
        <p style="  
        padding: 20px;
        font-family: Arial, sans-serif;
        font-size: 16px;
        color: #333;
        text-align: center;
        margin: 0; 
        line-height: 1.5; 
        font-weight: bold;"
        >For individuals seeking to apply for any of our programs, the application forms can be downloaded below:</p>
    <div class="file">
      <div class="file-info">
        <img src="assets/img/telone/pdf-icon.png" alt="PDF Icon" class="pdf-icon">
        <div class="file-details">
          <a href="assets/pdf/APPLICATION FORM DIPLOMA PROGRAM.doc" target="_blank" class="file-name">APPLICATION FORM DIPLOMA PROGRAM.doc</a>
          <span class="file-size">(128 Kb)</span>
        </div>
        <a href="assets/pdf/APPLICATION FORM DIPLOMA PROGRAM.doc" download class="download-button">Download</a>
      </div>
    </div>
    <div class="file">
      <div class="file-info">
        <img src="assets/img/telone/pdf-icon.png" alt="PDF Icon" class="pdf-icon">
        <div class="file-details">
          <a href="assets/pdf/APPLICATION FORM FOR SHORT COURSES WITH REQUIREMENTS.doc" target="_blank" class="file-name">APPLICATION FORM FOR SHORT COURSES WITH REQUIREMENTS.doc</a>
          <span class="file-size">(51 Kb)</span>
        </div>
        <a href="assets/pdf/APPLICATION FORM FOR SHORT COURSES WITH REQUIREMENTS.doc" download class="download-button">Download</a>
      </div>
    </div>
    <div class="file">
      <div class="file-info">
        <img src="assets/img/telone/pdf-icon.png" alt="PDF Icon" class="pdf-icon">
        <div class="file-details">
          <a href="assets/pdf/POST-GRAD DIPLOMA APPLICATION  FORM.xml" target="_blank" class="file-name">POST-GRAD DIPLOMA APPLICATION FORM</a>
          <span class="file-size">(51 Kb)</span>
        </div>
        <a href="assets/pdf/POST-GRAD DIPLOMA APPLICATION  FORM.xml" download class="download-button">Download</a>
      </div>
    </div>
  </div>
  


<!-- <img src="assets/img/telone/c1.png" alt="image">
<img src="assets/img/telone/c2.png" alt="image">
<img src="assets/img/telone/c3.png" alt="image">
<img src="assets/img/telone/c4.png" alt="image"> -->
