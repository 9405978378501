<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Online Courses</h2>
            <!-- <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a> -->
        </div>
        <ul class="nav-tabset">
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                <span class="active" (click)="switchTab($event, 'tab1')">Trending </span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                <span (click)="switchTab($event, 'tab2')">Technical Diploma </span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                <span (click)="switchTab($event, 'tab3')">Business Diploma </span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                <span (click)="switchTab($event, 'tab4')">Degree </span>
            </li>
            <!-- <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                <span (click)="switchTab($event, 'tab5')">Language </span>
            </li>
            
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                <span (click)="switchTab($event, 'tab6')">Management </span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                <span (click)="switchTab($event, 'tab7')">Photography </span>
            </li> -->
        </ul>
        <div class="tabs-container">
            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/where-you-study-matters.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/where-you-study-matters.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Information Technology</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>SHORT BUSINESS COURSE</span>
                                </div>
                                <h3>SOCIAL MEDIA MARKETING</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                   
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>3 DAYS 
                                    </li>
                                    <li class="courses-price">
                                        $90
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/art-tcfl.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/art-tcfl.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Information Technology</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>SHORT TELECOMMUNICATIONS COURSE</span>
                                </div>
                                <h3>GIGABIT  PASSIVE  OPTICAL  NETWORK (GPON)</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>5 DAYS
                                    </li>
                                    <li class="courses-price">
                                        $80 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/digital-marketing.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/digital-marketing.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>SHORT TELECOMMUNICATIONS COURSE</span>
                                </div>
                                <h3>WEB INTELLIGENCE</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>3 DAYS 
                                    </li>
                                    <li class="courses-price">
                                        $60 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/python-study.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/python-study.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Software Engineering</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>CLOUD COMPUTING </span>
                                </div>
                                <h3>CLOUD COMPUTING</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 DAYS
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $130
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/together-we-can.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/together-we-can.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>SHORT BUSINESS COURSE</span>
                                </div>
                                <h3>MANAGEMENT DEVELOPMENT PROGRAM</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>8 MONTHS	
                                    </li>
                                    <li class="courses-price">
                                    $550
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/psychometric-tests.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/psychometric-tests.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Mathematics</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>SHORT TELECOMMUNICATIONS COURSE</span>
                                </div>
                                <h3>BIG DATA ANALYTICS</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 DAYS
                                    </li>
                                    <li class="courses-price">
                                        $80
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/telone/psychometric-tests.jpg"  alt="image"></a> -->
                                <img src="../../../../../assets/img/telone/psychometric-tests.jpg"  alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">TECHNICAL DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                 <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>HEXCO</span>
                                </div>
                                <h3>Telecommunications Engineering</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                   
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>	3 years 
                                    </li>
                                    <li class="courses-price">
                                        $635
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/art-tcfl.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/art-tcfl.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Information Technology</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>HEXCO</span>
                                </div> 
                                <h3>Diploma in Software Engineering</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>3 Years
                                    </li>
                                    <li class="courses-price">
                                        $635 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/digital-marketing.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/digital-marketing.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">TECHNICAL DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>HEXCO</span>
                                </div> 
                                <h3>Diploma in Data Science</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>3 Years
                                    </li>
                                    <li class="courses-price">
                                        $635
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/python-study.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/python-study.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">TECHNICAL DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>HEXCO </span>
                                </div> 
                                <h3>Diploma in computer Networking</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 3 Years	
                                    </li>
                                    <li class="courses-price">
                                        <span>$780</span>
                                        $635
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/courses/together-we-can.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/courses/together-we-can.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">TECHNICAL DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>HEXCO</span>
                                </div> 
                                <h3>Diploma in Cyber Security</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>3 Years
                                    </li>
                                    <li class="courses-price">
                                    	$635
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/telone/business1.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/telone/600X458.----.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">BUSINESS DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image"> -->
                                </div> 
                                <h3>Diploma in Digital Marketing</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 3 Years
                                    </li>
                                    <li class="courses-price">
                                        $635
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/telone/business1.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/telone/business1.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">BUSINESS DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image"> -->
                                </div>
                                <h3>Diploma in Financial Engineering</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 3 Years
                                    </li>
                                    <li class="courses-price">
                                        $635
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/telone/business3.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/telone/business3.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">BUSINESS DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image"> -->
                                </div> 
                                <h3>Diploma in Business Analytics</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 3 Years
                                    </li>
                                    <li class="courses-price">
                                        <span>$700</span>
                                        $635
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="../../../../../assets/img/telone//graduate.jpg" alt="image"></a> -->
                                <img src="../../../../../assets/img/telone//graduate.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">TECHNICAL DEPARTMENT</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/telone/1920X1080.--.jpg" class="rounded-circle mr-2" alt="image"> -->
                                </div> 
                                <h3>Bachelor of Engineering (Honours ) Degree in Telecommunications Engineering</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>5 Years	
                                    </li>
                                    <li class="courses-price">
                                        $785
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses1.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>Steven Smith</span>
                                </div>
                                <h3>Professional IT Expert Certificate Course</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses4.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>Liam King</span>
                                </div>
                                <h3>Web Design for Developing Technology with Joy</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses2.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3>Certified Graphic Design with Free Project Course</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses3.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>James Anderson</span>
                                </div>
                                <h3>Photography Crash Course for Photographer</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses5.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3>Information About UI/UX Design Degree</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab7" *ngIf="currentTab === 'tab7'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses2.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3>Certified Graphic Design with Free Project Course</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses4.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>Liam King</span>
                                </div>
                                <h3>Web Design for Developing Technology with Joy</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses5.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3>Information About UI/UX Design Degree</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <!-- <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a> -->
                                <img src="assets/img/courses/courses6.jpg" alt="image">
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <!-- <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image"> -->
                                    <span>David Warner</span>
                                </div>
                               <h3>Photography Photo modify and Beautiful</h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>