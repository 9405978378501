<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<h1 style="color: rgb(124, 34, 34); text-align: center; margin-top: 40px; ">Administrators</h1>


    <div class="administrator" style=" margin: auto; width: 50%; padding: 10px;">
        <img src="assets/img/telone/mpala.png" alt="Administrator 1">
        <div class="description">
            <h2 style="color: rgb(124, 34, 34);">Canaan Mpala</h2>
            <h3 style="color: rgb(124, 34, 34);">The GM</h3>
            <h5 style="color: rgb(124, 34, 34);">Experience</h5>
            <p style="font-size: 13px;">Canaan is a results-oriented and visionary leader with extensive experience in academic administration and leadership roles within reputable educational institutions. He is an all-rounder and fine administrator and academic who served on both business and technical academic and training institutions in and outside the country. He has a proven track record of successfully managing and enhancing academic programs in various institutions, fostering faculty excellence, and ensuring student success. Skilled in strategic planning, resource mobilization, management, and stakeholder engagement. Canaan is committed to promoting a culture of innovation, inclusivity, and academic excellence at TelOne Centre for Learning. 

                Prior to joining TelOne Centre for Learning as a General Manager, he served the positions of Pro-Vice-Chancellor, Registrar, Senior Proctor, Dean of Faculty, Chairperson of Department at the Lupane State University. He also worked as lecturer at Great Zimbabwe University, University of Botswana and Bulawayo Polytechnic. Canaan also happens to be a Journeyman Class 1 Skilled worker in Automotive Engineering field. </p>
            <h5 style="color: rgb(124, 34, 34);">Achievements</h5>
           <p style="font-size: 13px;">Master of Business Administration (MBA) National University of Science & Technology </p>
           <p style="font-size: 13px;">Bachelor of Technical Education Honors Degree in Business Management.  National University of Science and Technology (NUST).</p>
           <p style="font-size: 13px;">National Diploma in Motor Industry Management. Bulawayo Polytechnic.  </p>
           <p style="font-size: 13px;">Zimbabwe Further Education Teachers Certificate (ZFETC) Bulawayo Polytechnic. </p>
            <p style="font-size: 13px;">Member of the Zimbabwe Institute of Management (ZIM)  </p>
        </div>
    </div>
<div class="container3">
    <div class="administrator">
        <!-- <img src="assets/img/telone/860X660.--.jpg" alt="Administrator 1"> -->
        <div class="description">
            <h3 style="color: rgb(124, 34, 34);">Precious Mutseyi</h3>
            <h4 style="color: rgb(124, 34, 34);">Administrator</h4>
            <h5 style="color: rgb(124, 34, 34);">Experience</h5>
            <p>The administrator's experience reflects a profound commitment to fostering organizational excellence through astute leadership, strategic foresight, and unwavering dedication to stakeholder empowerment. </p>
            <h5 style="color: rgb(124, 34, 34);">Vision</h5>
            <p>To foster a culture of excellence and innovation by leveraging effective leadership, strategic planning, and collaborative initiatives.</p>
        </div>
    </div>
    <div class="administrator">
        <!-- <img src="assets/img/telone/860X660.--.jpg" alt="Administrator 1"> -->
        <div class="description">
            <h3 style="color: rgb(124, 34, 34);">Tawanda Mapuranga</h3>
            <h4 style="color: rgb(124, 34, 34);">Training Manager</h4>
            <h5 style="color: rgb(124, 34, 34);">Experience</h5>
            <p>The administrator's experience reflects a profound commitment to fostering organizational excellence through astute leadership, strategic foresight, and unwavering dedication to stakeholder empowerment. </p>
            <h5 style="color: rgb(124, 34, 34);">Vision</h5>
            <p>To foster a culture of excellence and innovation by leveraging effective leadership, strategic planning, and collaborative initiatives.</p>
        </div>
    </div>
    <!-- <div class="administrator">
        <img src="assets/img/telone/860X660.--.jpg" alt="Administrator 1">
        <div class="description">
            <h4 style="color: rgb(124, 34, 34);">-------------------</h4>
            <h4 style="color: rgb(124, 34, 34);">Quality Assurance Officer</h4>
            <h5 style="color: rgb(124, 34, 34);">Experience</h5>
            <p>The administrator's experience reflects a profound commitment to fostering organizational excellence through astute leadership, strategic foresight, and unwavering dedication to stakeholder empowerment. </p>
            <h5 style="color: rgb(124, 34, 34);">Vision</h5>
            <p>To foster a culture of excellence and innovation by leveraging effective leadership, strategic planning, and collaborative initiatives.</p>
        </div>
    </div> -->
</div>

<div class="container3">
    <div class="administrator">
        <!-- <img src="assets/img/telone/860X660.--.jpg" alt="Administrator 1"> -->
        <div class="description">
            <h3 style="color: rgb(124, 34, 34);">Tanaka Komorai</h3>
            <h4 style="color: rgb(124, 34, 34);">Accountant</h4>
            <h5 style="color: rgb(124, 34, 34);">Experience</h5>
            <p>The administrator's experience reflects a profound commitment to fostering organizational excellence through astute leadership, strategic foresight, and unwavering dedication to stakeholder empowerment. </p>
            <h5 style="color: rgb(124, 34, 34);">Vision</h5>
            <p>To foster a culture of excellence and innovation by leveraging effective leadership, strategic planning, and collaborative initiatives.</p>
        </div>
    </div>

    <div class="administrator">
        <!-- <img src="assets/img/telone/860X660.--.jpg" alt="Administrator 1"> -->
        <div class="description">
            <h3 style="color: rgb(124, 34, 34);">Rudo Chiramba</h3>
            <h4 style="color: rgb(124, 34, 34);">Librarian</h4>
            <h5 style="color: rgb(124, 34, 34);">Experience</h5>
            <p> 
               She is a skilled librarian with expertise in research management, readers advisory services, publishing, and cataloguing. She holds a postgraduate qualification in Education and has excelled in various departments within university libraries.
            </p>
            <h5 style="color: rgb(124, 34, 34);">Achievements</h5>
            <p> Masters of Science Library and Information Science, National University of Science and Technology
                
                Bachelor of Science (Hons) Information Systems, Midlands State University 
               
                National Diploma in Library and Information Sciences, Bulawayo Polytechnic

                National Diploma in Development Studies, Lupane State University
                </p>
        </div>
    </div>
</div>

<div class="container2">
    <div class="mission-vision">
        <img src="assets/img/telone/860X660-Events-1.jpg" alt="Mission and Vision Image">
    </div>
    <div class="mission-text">
        <p style=" font-size: 20px; color: rgb(124, 34, 34);">
            <strong>Vision Statement</strong>  <br>To become a specialist ICT institution in Zimbabwe by 2025.
            <br>
            <strong>Mission Statement</strong>  <br>To provide ICT skills for the digital economy in Zimbabwe and beyond
        </p>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>