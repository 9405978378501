<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">TelOne Centre for Learning, Corner Hampden, Samora Machel, Belvedere, Harare</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">024 278 0126</a>
                        </li>
                        <i class='bx bx-twitter'></i>
                        <a href=" https://twitter.com/TelOnezw"></a>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@ednuv.com">studentaffairs@telone.co.zw</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href=" https://www.facebook.com/TelOneCFL" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href=" https://twitter.com/TelOnezw" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Department</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Student Affairs</a></li>
                        <li><a routerLink="/">Reception</a></li>
                        <li><a routerLink="/">Accounts</a></li>
                        <li><a routerLink="/">Library</a></li>
    
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Details</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">024 278 0134</a></li>
                        <li><a routerLink="/">024 278 0126 / 071 978 0128</a></li>
                        <li><a routerLink="/">071 978 0129</a></li>
                        <li><a routerLink="/">024 275 3169</a></li>
                    
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Banking details</h3>
                    <div class="newsletter-box">
                        <p>Account Name: TelOne</p>
                        <p>Bank: BANC ABC</p>
                        <p>Branch: Mt Pleasant</p>
                        <p>ZWL Account Number: 10109163902015</p>
                        <p>NOSTR Account Number: 10109166633189</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
