<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-three item-bg12">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Instructors</li>
            </ul>
            <h2>Team of Instructors</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor1.jpg" alt="images">
                    </div>
                    <!-- <div class="member-content">
                        <h3><a routerLink="/single-instructor">Jonkin Jullinor</a></h3>
                        <span>OOP Developer</span>
                        <p>Jonkin Jullinor holds three degrees from MIT and an MBA from Harvard.</p>
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor2.jpg" alt="images">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor3.jpg" alt="images">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor4.jpg" alt="images">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor5.jpg" alt="images">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor6.jpg" alt="images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>