<section class="events-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Events</span>
            <h2>Our Upcoming Events</h2>

        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg1">
                                <img src="assets/img/telone/ideas.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Wed, 03 June, 2023</span>
                                </div>
                                <h3><a >TCFL students shines at Huawei Seeds for the Future</a></h3>
                                <p>
                                    We are pleased to announce that 7 of our students were selected to participate at the Huawei Seeds for the Future. At platform that allows young innovators to pitch ideas which are then incubated by Huawei. Keep Shining TCFL! 
                                </p>
                                <span class="location"><i class="bx bx-map"></i> Huawei Seeds</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg2">
                                <img src="assets/img/business-coaching/events-img2.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Thu, 04 June, 2023</span>
                                </div>
                                <h3><a>New SCR structure in place</a></h3>
                                <p>
                                    TelOne Centre for Learning elected its first student council representatives. The move was welcome by both students and TCFL lecturers and Admin staff as it will go a long way in bridging a gap between the two parties and ensuring that all students needs are adequately meet and addressed
(Pic of SCR team )
</p>
                                <span class="location"><i class="bx bx-map"></i>Telone, Zimbabwe</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg3">
                                <img src="assets/img/business-coaching/events-img3.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Fri, 05 June, 2023</span>
                                </div>
                                <h3><a >TCFL launches campus Smart Wi-Fi </a></h3>
                                <p>The new Wi-Fi system utilises vouchers. These vouchers are issued to students upon registration. </p>
                                <span class="location"><i class="bx bx-map"></i>Telone, Zimbabwe</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg4">
                                <img src="assets/img/business-coaching/events-img4.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Sat, 06 June, 2023</span>
                                </div>
                                <h3><a >Enrolment adverts </a></h3>
                                <p>Are you ready to transform your aspirations into achievements? Secure your spot at Telone Learning Center and set the course for a future filled with endless possibilities.</p>
                                <span class="location"><i class="bx bx-map"></i>Telone, Zimbabwe</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape7"><img src="assets/img/business-coaching/business-shape4.png" alt="image"></div>
</section>