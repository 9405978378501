<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-two item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Category</li>
            </ul>
            <h2>Business</h2>
        </div>
    </div>
</div>
<div class="courses-categories-area pt-100 pb-70"  style="background-image: url('/assets/img/telone/partners/maths.jpg'); background-size: cover; background-position: center;">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx  bx-message-rounded-dots'></i>
                        Client Relationship Management
                        <br>
                        <span style="font-style: italic; font-size: 12px;">2 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$80 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-chart'></i>
                        Computer Packages For Data Analytics
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$90 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-pie-chart'></i>
                        Data Science (Hexco)
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days </span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$450 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-target-lock'></i>
                        Digital Marketing Management
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$80 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-heart'></i>
                        Emotional Intelligence
                        <br>
                        <span style="font-style: italic; font-size: 12px;">2 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$80 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>
           
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-shape-triangle'></i>
                        Executive Development Program
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Years </span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$600 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-dollar'></i>
                        Finance For Non Finance Managers
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$90 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>
           
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-book'></i>
                        Graduate Learnership Program
                        <br>
                        <span style="font-style: italic; font-size: 12px;">12 Months </span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$600 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-home'></i>
                        Humain Capital Managememnt
                        <br>
                        <span style="font-style: italic; font-size: 12px;">2 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$80 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-wallet'></i>
                        Key Account Managememnt
                        <br>
                        <span style="font-style: italic; font-size: 12px;">2 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$80 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-badge-check'></i>
                        Leadership Masterclass
                        <br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$100 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-chevron-up'></i>
                        Management Development Program
                        <br>
                        <span style="font-style: italic; font-size: 12px;">8 Months </span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$550 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

             
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-building'></i>
                        Personal Branding
                        <br>
                        <span style="font-style: italic; font-size: 12px;">2 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$80 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-walk'></i>
                        Project Managememnt
                        <br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$120 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-calculator'></i>
                        Sales Master class
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$90 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-target-lock'></i>
                        Selling Skills
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$90 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-laptop'></i>
                        Social Media Marketing
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$90 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-group'></i>
                        Supevisory Development Program
                        <br>
                        <span style="font-style: italic; font-size: 12px;">6 Months </span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$500 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-video-plus'></i>
                        Virtual Office Management
                        <br>
                        <span style="font-style: italic; font-size: 12px;">2 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$80 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-globe'></i>
                        Web Development
                        <br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <span style="font-style: italic; font-size: 12px;  ">$90 </span>
                      <br>
                      <br>
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>