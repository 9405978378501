<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg10">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Degrees</li>
            </ul>
            <h2>Degrees</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-6 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/telone/graduate.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-2-columns-style-1" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <h3 class="d-inline-block">Bachelor of Engineering (Honours ) Degree in TelecomBachelor of Engineering (Honours ) Degree in Telecommunications Engineering</h3>
                       <span style="font-size: 14px;">•3 A level passes in Mathematics, Physics and a third science subject.</span><br>
                       <span style="font-size: 14px;">•	5 Ordinary level passes including Mathematics, English and Science</span><br>
                       <span style="font-size: 14px;">•	You submit your certified copies of Advanced Level results, Ordinary Level results, National ID and Birth Certificate.</span>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i>5 Years	
                            </li>
                            <li class="courses-price">
                                $785/SEM
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>