<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2>Top Selling Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="courses-box">
                    <div class="courses-image">
                        <a class="d-block image">
                            <img src="assets/img/telone/600X485.---.jpg" alt="image">
                        </a>
                        <a  class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$600</div>
                    </div>
                    <div class="courses-content">
                        <h3>Bachelor of Engineering (Honours ) Degree in Telecommunications Engineering</h3>
                        <p>•3 A level passes in Mathematics, Physics and a third science subject.</p>
                        <p>•5 Ordinary level passes including Mathematics, English and Science.</p>
                        <p>•	You submit your certified copies of Advanced Level results, Ordinary Level results, National ID and Birth Certificate.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='bx bx-book-bookmark'></i> 5 Years</li>
                            <li><i class='bx bx-group'></i> $600</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/telone/600X485.jpg" alt="image">
                        </a>
                        <a class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$600</div>
                    </div>
                    <div class="courses-content">
                        <h3><a >Postgraduate Diploma in Business Analytics</a></h3>
                        <p>
                            • 5 Ordinary level passes including Mathematics, English and Science. (Technical)
                        </p>
                        <p>
                            • 5 Ordinary level passes including English and Mathematics (Business)
                        </p>
                        <p>
                            • You submit your certified copies of Ordinary Level results, National ID and Birth Certificate.
                        </p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='bx bx-book-bookmark'></i> 1 Year</li>
                            <li><i class='bx bx-group'></i> $600</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/telone/600X485.----.jpg" alt="image">
                        </a>
                        <a class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$600</div>
                    </div>
                    <div class="courses-content">
                        <h3><a >Postgraduate Diploma in Digital Financial Engineering</a></h3>
                        <p>
                            • 5 Ordinary level passes including Mathematics, English and Science. (Technical)
                        </p>
                        <p>
                            • 5 Ordinary level passes including English and Mathematics (Business)
                        </p>
                        <p>
                            • You submit your certified copies of Ordinary Level results, National ID and Birth Certificate.
                        </p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='bx bx-book-bookmark'></i> 1 Year</li>
                            <li><i class='bx bx-group'></i> $600</li>
                        </ul>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Enjoy the top notch learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape5"><img src="assets/img/business-coaching/business-shape4.png" alt="image"></div>
</section>