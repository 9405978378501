<app-header-style-one></app-header-style-one>

<app-hometwo-main-banner></app-hometwo-main-banner>

<app-hometwo-about></app-hometwo-about>

<div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/business" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-two></app-categories-style-two>
    </div>
</div>

<!-- <div class="funfacts-area pt-10">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div> -->

<app-hometwo-courses></app-hometwo-courses>

<app-our-mission></app-our-mission>

<!-- <div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div> -->

<div class="instructor-area pt-100 pb-70">
    <!-- <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>
                Imagine a cohesive and dynamic team of instructors at the heart of our educational ecosystem, each member bringing a unique set of expertise and passion to the table. United by a shared commitment to excellence in teaching, this team embodies diversity in thought and specialization.
            </p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div> -->
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Our Student Representative Commite</h2>
            <p>
                TelOne Centre for Learning elected its first student council representatives. The move was welcome by both students and TCFL lecturers and Admin staff as it will go a long way in bridging a gap between the two parties and ensuring that all students needs are adequately meet and addressed
            </p>
        </div>
        <app-instructors-style-three></app-instructors-style-three>
    </div>
</div>

<app-students-feedback-form></app-students-feedback-form>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <!-- <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
        </div>
        <app-blog></app-blog>
    </div>
</div>

<app-webinar-countdown></app-webinar-countdown>