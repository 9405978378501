<app-header-style-one></app-header-style-one>

<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/content/learn.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <!-- <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div> -->
                            <h3>Register for your diploma or degree</h3>
                            <br>
                            <br>
                            <p>
                                Benefit from expert-led courses, industry-relevant curriculum, and a supportive learning environment. Secure your spot, and let TelOne be your partner in achieving academic and professional success.
                            </p>
                            <br>
                            <br>
                            <h4><b>How to apply</b></h4>
                            <br>
                                 <p>
                                •	To apply (Degree, Diploma, Post-Grad), one has to submit certified copies of birth, national ID and results (O’ level and A’ level where necessary) along with filled in application form.
                               </p> 
                               <p>•	The application form should be attached with proof of application fee payment
                               </p>
                               <p>
                                •	For all short courses one has to submit filled in application form with certified copy of birth and ID and O’ level result where necessary
                                </p>
                            <!-- <form>
                                <div class="form-group">
                                    <input type="name" placeholder="Your name" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="courses" placeholder="Select courses" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="email" placeholder="Your email address" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="password" placeholder="Your password" class="form-control">
                                </div>
                                <button type="submit">Register</button>
                            </form> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

